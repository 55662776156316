@font-face {
  font-family: "OldSansBlack";
  src: url("../fonts/OldSansBlack.ttf");
}
.center {
  text-align: center;
}
.block-text {
  text-align: center;
  max-width: 100%;
  display: inline-block;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 4%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #d2effe;
  color: #68b4b5;
  font-family: "OldSansBlack";
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.count-down{
  margin-top: 20px;
  margin-bottom: 0px;
}

.yeti-carousel-image {
  width: 100%;
  height: 100%;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #68b4b5;
  font-size: 12px;
  border: 1px solid #68b4b5;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  height: 60px;
  width: 80px;
}

.green-btn {
  background-color: #68b4b5;
}

.mint-btn {
  width: 255px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn:hover {
  background-color: #68b4b5;
}

.btn-block {
  display: block;
  width: 100%;
}

.story-wrapper {
  width: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.story-box {
  width: 60%;
  padding: 10px;
}

.story-text {
  justify-content: center;
}

.story-btn {
  text-decoration: none;
  color: #68b4b5;
  border: none;
  background-color: #d2effe;
  margin-bottom: 5vh;
}

.terms-btn {
  text-decoration: none;
  color: #68b4b5;
  border: none;
  background-color: #d2effe;
  margin: 5vh 0vh;
  font-size: 9px;
}

.mint-input {
  text-align: center;
  width: 80px;
  height: 60px;
  margin: 5px;
  border-radius: 5%;
  /* border: 1px solid rgba(1,1,1,0.5); */
  border: 1px solid #68b4b5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-and-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-wallet {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 1%;
}

.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 30px;
  padding: 10px 20px;
}

.links {
  padding: 2%;
  text-decoration: none;
}

/* Style the links inside the navigation bar */

.topnav-right {
  display: flex;
  gap: 20px;
  align-items: center;
  align-content: center;
}

.topnav-left {
  display: flex;
  gap: 30px;
  align-items: center;
  align-content: center;
}

/* Right-aligned section inside the top navigation */

.main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin: 12vh 0vh 16vh 0vh;
  max-width: 100%;
  height: 70vh;
}

.main-left {
  width: 39%;
}

.main-right {
  width: 49%;
  border: 3px solid #68b4b5;
  height: fit-content;
  padding: 3%;
}

.mtns {
  width: 100%;
  height: 15vh;
}

.title-and-description {
  text-align: center;
  width: 100%;
  margin: 0;
}

.title-and-description h1 {
  margin: 0;
}

.title-and-description h2 {
  margin: 0;
}

.title-and-description p {
  margin: 0;
}

.title {
  font-size: 60px;
}

.minting-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
}

.minting-section h2 {
  margin: 0;
}

.yeti-banner {
  height: 100%;
}

.carousel-control-prev {
  visibility: hidden;
}

.carousel-control-next {
  visibility: hidden;
}

.bio-section {
  display: flex;
  justify-content: space-around;
  margin-top: 10vh;
  margin-right: 10vw;
  margin-left: 10vw;
}

.the-gang {
  width: 80%;
}

.main-top {
  align-items: center;
  justify-content: space-around;
  display: flex;
}

.bio-square {
  display: flex;
  align-items: center;
  width: 25%;
}

.bio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 20vw;
}

.bio-card h6 {
  font-size: 24px;
  margin-top: 10px;
}

.bio-card h4 {
  font-size: 20px;
}

.bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10vh;
}

.avatar {
  width: 100px;
}

.icon {
  height: 30px;
  width: 30px;
  display: inline-block;
}

.icon:hover {
  color: #c1dedf;
}

/* media query for mobile */

@media screen and (max-width: 600px) {
  .icon {
    height: 25px;
    width: 25px;
    display: inline-block;
  }

  .icon:hover {
    color: #c1dedf;
  }

  .user-wallet {
    font-size: 14px;
    margin-left: 12px;
  }

  .main {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 0;
  }

  .main-left {
    width: 80%;
    margin: 4% 0;
  }

  .main-right {
    width: 80%;
    border: 3px solid #68b4b5;
  }

  .title {
    font-size: 40px;
  }

  .bio-section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .topnav-left {
    display: flex;
    gap: 2px;
    align-items: center;
    align-content: center;
  }

  .topnav {
    font-size: 25px;
  }

  .story-btn {
    text-decoration: none;
    color: #68b4b5;
    border: none;
    background-color: #d2effe;
    margin-bottom: 5vh;
    margin-top: 3vh;
  }

  .mint-btn {
    width: 100%;
    height: 100%;
  }

  .mtns {
    height: 7vh;
  }

  .the-gang {
    display: none;
  }
}
